import { DefaultButton, IconButton, Label, Modal, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import { App } from '../../App';
import { I18n, I18nField } from '../../services/i18n';
import { Office365API } from '../../services/o365';
import { ImagePage } from '../pages/imageCreator';

export class DeleteImagePopUp extends React.Component<{
    isOpen: boolean,
    onDismiss?: ((ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => any) | undefined,
    contentStyles?: { container: string | undefined, header: string | undefined, body: string | undefined },
    cancelImageDelete?: ((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => void) | undefined
}>{
    render() {
        return <Modal isOpen={this.props.isOpen}>
            <div className={this.props.contentStyles?.header}>
                <span id='UploadTitleArea'><I18nField textID='delete_image' /></span>

                <IconButton
                    styles={{
                        root: {
                            marginLeft: 'auto',
                            marginTop: '4px',
                            marginRight: '2px'
                        }
                    }}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={this.props.cancelImageDelete}
                />
            </div>
            <div className={this.props.contentStyles?.body}>
                <Label htmlFor="ImageCreator"><I18nField textID='labelPreviewImage' /></Label>
                <ImagePage />
                <Stack horizontal styles={{ root: { marginTop: 20 } }} tokens={{ childrenGap: 20 }}>
                    <PrimaryButton text={I18n.i18nRef.current?.getString('delete_image')} onClick={this.doDeleteImage.bind(this)} />
                    <DefaultButton onClick={this.props.cancelImageDelete} text={I18n.i18nRef.current?.getString('cancel')} />
                </Stack>
            </div>
        </Modal>;
    }

    doDeleteImage() {
        App.instance.setState({ spinner: true });
        var canvas = document.getElementById('ImageCreator') as HTMLCanvasElement;
        canvas.toBlob((theBlob) => {
            console.log(theBlob);
            var file = new File([theBlob as Blob], "yourInitials.jpeg");
            Office365API.Instance.deleteProfileImage(file).then(() => {
                App.instance.resetDeleteState();
                App.instance.reloadState();
            });
        }, 'image/jpeg', 1);
    }
}