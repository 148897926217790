import { DefaultButton, List } from '@fluentui/react';
import * as React from 'react';

export class NavigationSideBar extends React.Component {
    private _items = [
        { name: 'Office 365', url: 'https://office.com', image: './Microsoft_Office_logo.svg' },
        // { name: 'Audi mynet', url: 'https://portal.epp.audi.vwg', image: './mynet Logo.svg' },
        // { name: 'Audi dox', url: 'https://dox.epp.audi.vwg/', image: './dox Logo.svg' },
        // { name: '360° Volkswagen Net', url: 'https://volkswagen-net.de/', image: './Volkswagen net Logo.svg' }
        
    ];
    render() {


        return < List style={{ paddingTop: 10 }}
            items={this._items}
            onRenderCell={this._onRenderCell}
        />
    }
    private _onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        let _height = 50;
        return <DefaultButton
            styles={{
                root: { height: _height + 4, verticalAlign: 'midle', width: '100%' },
                icon: { height: _height, verticalAlign: 'midle' },
                label: { verticalAlign: 'midle' }
            }}
            text={item.name}
            iconProps={{
                imageProps: {
                    src: item.image, width: 50,
                    height: 50,
                }
            }}
            menuIconProps={{ iconName: 'More' }}
            menuProps={{
                items: [
                    {
                        key: 'openinNewWindow',
                        text: 'in neuer Registerkarte öffnen',
                        href: item.url,
                        target: '_blank'
                    },
                    {
                        key: 'openhere',
                        text: 'hier öffnen',
                        href: item.url
                    },
                ]
            }}
            allowDisabledFocus
        />

    }
}