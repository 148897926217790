import { GraphError } from '@microsoft/microsoft-graph-client';
import { FontWeights, getTheme, mergeStyleSets, Modal, Spinner } from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import React from 'react';
import { I18n } from './services/i18n';
import { Office365API } from './services/o365';
import * as policyConfigJSON from './services/policies.json';
import { DeleteImagePopUp } from './ui/components/DeleteImagePopUp';
import { Shell } from './ui/components/Shell';
import { UploadPopUp } from './ui/components/UploadPopUp';

initializeIcons(/* optional base url */);

export class App extends React.Component {

  state = {
    uploadOpen: false,
    fileToUpload: ({} as File),
    imagePreview: ({} as any),
    imageSelectionPreview: ({} as any),
    o365User: ({} as any),
    photoDetails: ({} as any),
    policyAccepted: false,
    policyDetails: ({} as any),
    policyConfig: ({} as any),
    spinner: false,
    deleteImageOpen: false,
    languages: [
      { key: 'de-DE', name: 'deutsch' },
      { key: 'en-US', name: 'english' },
      { key: 'fr-FR', name: 'français' },
      { key: 'pl-PL', name: 'polski' },
      { key: 'sk-SK', name: 'slovenčina' },
      { key: 'pt-BR', name: 'Português do Brasil' },
      { key: 'pt-PT', name: 'Português' },
      { key: 'it-IT', name: 'italiano' },
      { key: 'cz-CZ', name: 'Čeština' },
      { key: 'es-ES', name: 'Español' }
    ],
    choosenLanguage: '',
    showLoginError: false,
    loginError: ''
  };
  public static instance: App;
  login() {
    // Office365API.Instance.login();
    Office365API.Instance.getMe().then((userDetails) => {
      this.setState({ o365User: userDetails });
    }).catch((error) => {
      App.instance.handleLoginError(error);

    });
  }
  componentDidMount() {
    this.setState({ policyConfig: policyConfigJSON, choosenLanguage: 'de-DE' });


    this.login();

  }

  reloadState() {
    Office365API.Instance.getMyPhotoUrl().then((photoDetails) => {
      App.instance.setState({ photoDetails: photoDetails });
    });
    Office365API.Instance.getMyExtension("vwg.imagePolicy").then((policyDetails) => {
      App.instance.setState({ policyDetails: policyDetails });
    });
  }
  render() {
    let theme = getTheme();
    const contentStyles = mergeStyleSets({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
      },
      header: [

        theme.fonts.xxLarge,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px',
        },
      ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
        },
      },
    });
    App.instance = this;

    return <><I18n key={this.state.choosenLanguage} ref={I18n.i18nRef} locale={this.state.choosenLanguage} localesFolder='locales'><Shell></Shell></I18n>
      <UploadPopUp isOpen={this.state.uploadOpen} onDismiss={this.cancelImageUpload.bind(this)} onCancelImageUpload={this.cancelImageUpload.bind(this)}
        contentStyles={contentStyles} />
      <DeleteImagePopUp isOpen={this.state.deleteImageOpen} onDismiss={this.cancelImageDelete.bind(this)} contentStyles={contentStyles} cancelImageDelete={this.cancelImageDelete.bind(this)} />
      <Modal isOpen={this.state.spinner} styles={{ scrollableContent: { paddingTop: 50 } }}>
        <Spinner label={I18n.i18nRef.current?.getString('uploadingImage')} />
      </Modal>
    </>;

  }
  resetUploadState() {
    App.instance.setState({ fileToUpload: {}, uploadOpen: false, spinner: false, imagePreview: {}, policyAccepted: false });
  }
  resetDeleteState() {
    App.instance.setState({ fileToUpload: {}, uploadOpen: false, spinner: false, imagePreview: {}, policyAccepted: false, deleteImageOpen: false });
  }


  startImageUpload() {
    this.setState({ uploadOpen: true });
  }
  startImageDelete() {
    this.setState({ deleteImageOpen: true });
  }
  cancelImageDelete() {
    this.setState({ deleteImageOpen: false });
  }
  cancelImageUpload() {
    this.resetUploadState();
  }

  getInitials() {
    let initials: string = "";
    initials += (this.state.o365User["givenName"] ? this.state.o365User["givenName"].charAt(0) : "");
    initials += (this.state.o365User["surname"] ? this.state.o365User["surname"].charAt(0) : "");
    if (initials === "") {
      initials = this.state.o365User["displayName"].charAt(0);
      let position = (this.state.o365User["displayName"] as string).search(' ');
      if (position > 0)
        initials += this.state.o365User["displayName"].charAt(position + 1);
    }
    return initials;
  }

  handleLoginError(error: GraphError) {
    let ssoPos = error.message?.search('Seamless single sign on');
    console.error(error);
    if (ssoPos >= 0) {
      App.instance.setState({ loginError: 'ssoError', showLoginError: true });
      //retry
      // this.login();
    } else if (error.code === 'InteractionRequiredAuthError') {
      App.instance.setState({ loginError: 'noAuth', showLoginError: true });
    } else if (error.code === 'ClientAuthError') {
      App.instance.setState({ loginError: error.message, showLoginError: true });
    } else {
      App.instance.setState({ loginError: 'notLoggedIn', showLoginError: true });
    }
  }
};
