
import * as React from "react";
import { App } from "../App";

export class I18n extends React.Component<{ locale: string | 'de-DE', localesFolder: string }, { textFields: any, textFieldsLoaded: boolean }> {
    public static i18nRef = React.createRef<I18n>();
    public static instance: I18n;
    constructor(props: { locale: string | 'de-DE', localesFolder: string }) {
        super(props);
        this.state = { textFields: { welcomeToTheApplication: 'Willkommen im Profilbildeditor', pleaseLogin: 'Bitte anmelden', noAuth: 'Leider haben die derzeit nicht die Berechtigung diese Anwendung zu nutzen.', ssoError: 'Es gibt ein Problem mit der SSO-Anmeldung. Anmeldebutton bitte nochmals anklicken.',
        notLoggedIn:"Automatische Anmeldung nicht möglich, auf \"Bitte anmelden\" klicken" }, textFieldsLoaded: false };
        I18n.instance = this;
    }
    componentDidMount() {

        this.fetchLocale();

    }

    componentDidUpdate(prevProps: { locale: string, localesFolder: string }) {
        if (prevProps.locale !== this.props.locale || prevProps.localesFolder !== this.props.localesFolder) {
            this.fetchLocale();
        }
    }

    public fetchLocale() {
        this.setState({ textFields: {}, textFieldsLoaded: false });
        let localToFetch = (this.props.locale) ? this.props.locale : 'de-DE';
        if (!localToFetch || !this.props.localesFolder) return;
        fetch(`${process.env.PUBLIC_URL}/${this.props.localesFolder}/${localToFetch}.json`).then((result) => {
            result.json().then((jsonLocale) => {
                I18n.instance.setState({ textFields: jsonLocale, textFieldsLoaded: true });
                App.instance.forceUpdate();
            });

        });
    }
    public getString(id: string, variables?: string[]) {
        if (!this.state?.textFields[id]) {
            return `<${id}-${this.props.locale}>`;
        }
        return setVariable(this.state?.textFields[id], variables);
    }
    render() {

        return <>{this.props.children}</>;
    }

    private fieldsToUpdate: I18nField[] = [];

    addField(newField: I18nField) {
        console.log(`adding Field`);
        this.fieldsToUpdate.push(newField);
        newField.setState({ addedToI18: true });
    }
}

export class I18nField extends React.Component<{ textID: string, variables?: string[] }, { uniqueID: string, addedToI18: boolean }> {

    constructor(props: { textID: string, variables?: string[] }) {
        super(props);
        this.state = { uniqueID: `${this.props.textID}-${Date.now()}`, addedToI18: false };
    }

    render() {
        return <I18nFieldInner key={this.state.uniqueID} locale={I18n.instance.props.locale} textID={this.props.textID} variables={this.props.variables} />
    }

}

class I18nFieldInner extends React.Component<{ locale: string | undefined, textID: string, variables?: string[] }> {
    state = { lastUpdate: Date.now() };
    componentDidMount() {
        this.setState({ lastUpdate: Date.now() });
    }
    render() {

        if (!I18n.instance.state?.textFields[this.props.textID]) {
            return <>{`<${this.props.textID}-${this.props.locale}-${(I18n.instance.state?.textFieldsLoaded === true) ? 'missing' : 'loading'}>`}</>;
        }
        return <>{setVariable(I18n.instance.state?.textFields[this.props.textID], this.props.variables)}</>;

    }

}

function setVariable(text: string, variables?: string[]) {
    let retText = text;
    if (!retText) return '';
    if (variables) {
        for (let i = 0; i < variables.length; i++) {
            retText = retText.replaceAll(`{${i}}`, variables[i]);
        }
    }
    return retText;
}