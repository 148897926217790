import { MessageBar, MessageBarType, PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import { App } from '../../App';
import { I18n, I18nField } from '../../services/i18n';
import { Office365API } from '../../services/o365';




export class LoginPage extends React.Component {
  login() {
    Office365API.Instance.login();
    // Office365API.Instance.getMe().then((userDetails) => {
    //   App.instance.setState({ o365User: userDetails });
    // }).catch((error: GraphError) => {

    //   App.instance.handleLoginError(error);
    // });

  }

  render() {
    let msgBar = <></>;
    if (App.instance.state.showLoginError) {
      msgBar = <MessageBar messageBarType={MessageBarType.blocked} >{I18n.instance.getString(App.instance.state.loginError)}</MessageBar>;
    }

    return <Stack key={App.instance.state.choosenLanguage + 'LoginPage'}
      id={App.instance.state.choosenLanguage + 'LoginPage'}
      styles={{ root: { paddingTop: 10, paddingLeft: 10, paddingRight: 10, height: '100%' }, inner: { alignItems: "center", verticalAlign: 'middle' } }} >

      <div style={{ textAlign: "center", paddingBottom: 50 }}><Text variant="xxLargePlus"><I18nField textID='welcomeToTheApplication' /></Text></div>
      <div style={{ textAlign: "center" }}>
        <PrimaryButton text={I18n.instance.getString('pleaseLogin')} width={150} onClick={this.login.bind(this)} /></div>
      {/* <div style={{ textAlign: "center", paddingTop: 0 }}><Login /></div> */}
      <div style={{ textAlign: "center", paddingTop: 50 }}>
        {msgBar}</div>


    </Stack >;
  }

};

