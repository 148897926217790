import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import { ContentPane } from '../components/ContentPane';
import { PolicyLoader } from '../../services/policyLoader';
import { I18n } from '../../services/i18n';
import { App } from '../../App';





export class PolicyPage extends React.Component<{}, { policyData: any }> {



    componentDidMount() {
        this.setState({ policyData: PolicyLoader.policyLoaderRef.current?.state?.policyData });
    }
    render() {
        let _versions: PivotItem[] = [];
        if (PolicyLoader.policyLoaderRef.current?.getActivePolicy()) {
            _versions.push(this.getPivotItem(PolicyLoader.policyLoaderRef.current?.getActivePolicy() as string, true));
        }
        PolicyLoader.policyLoaderRef.current?.getPolicyVersions().forEach((value, index) => {
            _versions.push(this.getPivotItem(value));
        });



        return <><PolicyLoader ref={PolicyLoader.policyLoaderRef} key={`${App.instance?.state?.choosenLanguage}polcies`} locale={App.instance?.state?.choosenLanguage} policiesFolder="policies"><ContentPane headerText={I18n.i18nRef.current?.getString('labelConsentVersion')}>
            <Pivot >{_versions}</Pivot>
        </ContentPane></PolicyLoader></>;
    }

    getPivotItem(version: string, isActive = false): PivotItem {
        let item = <PivotItem key={isActive ? version : `${version}active`} headerText={isActive ? I18n.i18nRef.current?.getString('presentVersion', [version]) : version}>
            <div id="policy" dangerouslySetInnerHTML={{
                __html: PolicyLoader.policyLoaderRef.current?.getPolicyConcatenated(version)
            }} />
        </PivotItem>;
        return item as unknown as PivotItem;
    }
}