import { CommandBar, Dropdown, getTheme, ICommandBarItemProps, ICommandBarStyleProps, ICommandBarStyles, IComponentAs, IContextualMenuItem, IDropdownOption, Image, IStyleFunctionOrObject, Label, Text } from '@fluentui/react';
import * as React from 'react';
import { App } from '../../App';
import { I18n } from '../../services/i18n';

export class HeaderCommandbar extends React.Component<{
    appLogo?: string,
    appText?: String,
    appStarterFunction: ((ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) => boolean | void) | undefined;
}> {
    private theme = getTheme();
    state = { officeUser: ({} as any) }
    static defaultProps = {
        appLogo: "",
        appText: '',
        appStarterFunction: function () { }
    };
    // componentDidMount() {
    //     // Office365API.Instance.getMe().then((userDetails) => {
    //     //     App.instance.setState({ o365User: userDetails });
    //     // });
    // }
    render() {
        let _LanguageDropDown: IComponentAs<ICommandBarItemProps> = (commandProps) => {
            let _options: IDropdownOption[] = [];
            App.instance.state.languages.forEach((value) => {
                _options.push({ key: value.key, text: value.name });
            });
            return <><Label htmlFor='LanDropDown' styles={{ root: Object.assign({}, commandProps.buttonStyles?.root, commandProps.buttonStyles?.textContainer) }} >{commandProps.text}</Label><Dropdown id='LanDropDown' selectedKey={App.instance.state.choosenLanguage} options={_options} styles={commandProps.buttonStyles} onChange={(ev, option) => {
                if (option) {
                    App.instance.setState({ choosenLanguage: option.key });
                    App.instance.forceUpdate();
                }
            }} /></>;
        };
        let _logoConfig: IComponentAs<ICommandBarItemProps> = (commandProps) => {
            return <Image src={this.props.appLogo} style={commandProps.style} styles={commandProps.buttonStyles} />;
        };
        let _items: ICommandBarItemProps[] = [
            {
                key: 'AppStarter',
                text: 'App-Startfeld',
                iconProps: { iconName: 'WaffleOffice365' },
                iconOnly: true,
                buttonStyles: {
                    icon: {
                        color: this.theme.palette.themeLighter,
                        fontSize: this.theme.fonts.large.fontSize
                    },
                    iconHovered: { color: this.theme.palette.themeDarker },
                    root: {
                        background: this.theme.palette.themePrimary
                    },
                    rootHovered: {
                        background: this.theme.palette.themeSecondary
                    }
                },
                onClick: this.props.appStarterFunction,
                style: {
                    width: '48px',
                    height: '48px'
                }
            },
            {
                key: 'AppLogo',
                text: 'App-Logo',
                commandBarButtonAs: _logoConfig,
                style: { paddingLeft: '24px', paddingTop: '8px', height: '32px' },
                buttonStyles: { root: { height: '48px' } }
            },
            {
                key: 'AppText',
                text: `${this.props.appText}`,
                commandBarButtonAs: (commandProps) => {
                    return <Text variant='mediumPlus' styles={{ root: Object.assign({}, commandProps.buttonStyles?.root, commandProps.buttonStyles?.textContainer) }}>{commandProps.text}</Text>;
                },
                style: { paddingLeft: '24px', paddingTop: '8px', height: '32px' },
                buttonStyles: { root: { height: '48px', paddingRight: '5px' }, textContainer: { color: '#ffffff', verticalAlign: 'center', lineHeight: '48px', paddingLeft: '15px', fontWeight: 'bold' } },
            },
            {
                key: 'ClassificationText',
                text: ` - ${I18n.instance.getString('internalData')} - `,
                commandBarButtonAs: (commandProps) => {
                    return <Text variant='mediumPlus' styles={{ root: Object.assign({}, commandProps.buttonStyles?.root, commandProps.buttonStyles?.textContainer) }}>{commandProps.text}</Text>;
                },
                style: { paddingLeft: '24px', paddingTop: '8px', height: '32px' },
                buttonStyles: { root: { height: '48px', paddingRight: '5px' }, textContainer: { color: '#ffffff', verticalAlign: 'center', lineHeight: '48px', paddingLeft: '15px', fontWeight: 'bold' } },
            }
        ];
        let _style:
            IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> = {
            root: {
                paddingLeft: '0px',
                background: this.theme.palette.themePrimary,
                height: '48px'
            }
        };
        let _farItems: ICommandBarItemProps[] = [
            {
                key: 'myLanguage',
                text: I18n.instance.getString('selectLanguage'),
                iconProps: { iconName: 'Contact' },
                commandBarButtonAs: _LanguageDropDown,
                iconOnly: false,
                buttonStyles: { root: { height: '48px', marginTop: '5px', paddingRight: '5px', display: App.instance.state.o365User['displayName'] ? 'block' : 'block' }, textContainer: { color: '#ffffff', verticalAlign: 'center', marginTop: '10px', } },
            },
            {
                key: 'myUser',
                text: `${App.instance.state.o365User['displayName'] ? App.instance.state.o365User['displayName'] : 'nicht angemeldet'}`,
                iconProps: { iconName: 'Contact' },
                href: `${App.instance.state.o365User['displayName'] ? '' : '#/login'}`,
                commandBarButtonAs: (commandProps) => {
                    return <Text styles={{ root: Object.assign({}, commandProps.buttonStyles?.root, commandProps.buttonStyles?.textContainer) }}>{commandProps.text}</Text>;
                },
                iconOnly: false,
                disabled: (!App.instance.state.o365User['displayName']),
                buttonStyles: { root: { height: '48px', paddingRight: '5px', display: App.instance.state.o365User['displayName'] ? 'block' : 'none' }, textContainer: { color: '#ffffff', verticalAlign: 'center', lineHeight: '48px', paddingLeft: '15px' } },
            }
        ];
        return <CommandBar styles={_style} items={_items} theme={this.theme} farItems={_farItems}></CommandBar>;
    }
};

