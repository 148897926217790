
import * as React from "react";
import { AppLoader } from "../ui/loader";
import { I18n } from "./i18n";

export class PolicyLoader extends React.Component<{ locale: string, policiesFolder: string }, { policyData: { activeVersion: string, policies: any } }> {
    public static policyLoaderRef = React.createRef<PolicyLoader>();
    componentDidMount() {

        this.fetchLocale();

    }

    componentDidUpdate(prevProps: { locale: string, policiesFolder: string }) {
        if (prevProps.locale !== this.props.locale || prevProps.policiesFolder !== this.props.policiesFolder) {
            this.fetchLocale();
        }
    }
    private fetchLocale() {
        if (!this.props.locale || !this.props.policiesFolder) return;
        fetch(`${process.env.PUBLIC_URL}/${this.props.policiesFolder}/${this.props.locale}.json`).then((result) => {
            result.json().then((jsonLocale) => {
                this.setState({ policyData: jsonLocale });
            });

        });

    }
    public getActivePolicy() {
        if (!this.state?.policyData?.activeVersion) {
            return I18n.i18nRef.current?.getString('loadingWithDots');
        }
        return this.state?.policyData?.activeVersion;
    }
    public getPolicy(version: string) {
        if (!this.state?.policyData?.policies[version]) {
            return I18n.i18nRef.current?.getString('loadingWithDots');
        }
        return this.state?.policyData?.policies[version];
    }
    public getPolicyConcatenated(version: string) {
        if (!this.state?.policyData?.policies[version]) {
            return I18n.i18nRef.current?.getString('loadingWithDots');
        }
        return this.state?.policyData?.policies[version].join("")
            .replaceAll(`{spoURL}`, AppLoader.instance.state.settings.spoURL)
            .replaceAll(`{companyName}`, AppLoader.instance.state.settings.companyName)
            .replaceAll(`{groupName}`, AppLoader.instance.state.settings.groupName)
            .replaceAll(`{tenantName}`, AppLoader.instance.state.settings.tenantName)
            .replaceAll(`{dsoMail}`, AppLoader.instance.state.settings.dsoMail)
            .replaceAll(`{dsoPostal}`, AppLoader.instance.state.settings.dsoPostal);
    }
    public getPolicies() {
        if (!this.state?.policyData?.policies) {
            return I18n.i18nRef.current?.getString('loadingWithDots');
        }
        return this.state?.policyData?.policies;
    }
    public getPolicyVersions(): string[] {
        if (!this.state?.policyData?.policies) {
            return [];
        }
        return Object.keys(this.state?.policyData?.policies);
    }

    render() {

        return <>{this.props.children}</>;
    }
}
