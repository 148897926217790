import { ScrollablePane } from '@fluentui/react';
import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { App } from '../../App';
import { EditorPage } from '../pages/editor';
import { LoginPage } from '../pages/login';
import { HeaderCommandbar } from './HeaderCommandbar';
import { SidePanel } from './SidePanel';


export class Shell extends React.Component {

    private static sidePanelRef = React.createRef<SidePanel>();


    render() {
        var componentToShow: any = LoginPage;
        if (App.instance.state.o365User['displayName']) {
            componentToShow = EditorPage;
        }
        return <HashRouter>
            <HeaderCommandbar appLogo='myProfileImage.svg' appText='myPhoto' appStarterFunction={this._onAppStarterClick} />
            <ScrollablePane styles={{ root: { marginTop: 48 } }}>

                <Switch>
                    <Redirect exact strict from="/" to="/editor" />
                    <Route path='/login' component={componentToShow} />
                    <Route path='/editor' component={componentToShow} />
                </Switch>
            </ScrollablePane>
            
            <SidePanel ref={Shell.sidePanelRef} />

        </HashRouter>;
    }

    private _onAppStarterClick() {
        if (Shell.sidePanelRef.current) Shell.sidePanelRef.current.openPanel();

    }
}
