import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions';
import "isomorphic-fetch";
import * as Msal from 'msal';
import { App } from "../App";
import { AppLoader } from "../ui/loader";

// let isDev = false;
// console.log(window.location.hostname);
// if (window.location.hostname === 'localhost') {
//     isDev = true;
// }

// const redirectURI = isDev ? 'http://localhost:3000/' : `https://iuvo485.in.audi.vwg:8080/`;
// const redirectURI = isDev ? 'http://localhost:3000/' : `msal2e99e35e-4410-4968-9c01-e76f2bc485f3://auth`;
// const redirectURI = 'http://localhost:3000/';

export class Office365API {

    async getUserInfo() {
        let infoUrl = "/.auth/me";
        if (window.location.hostname === 'localhost') {
            infoUrl = 'sampleData/auth_me.json';
        }
        const response = await fetch(infoUrl);
        const payload = await response.json();
        const { clientPrincipal } = payload;
        let typedPrincipal: {
            identityProvider: string,
            userId: string,
            userDetails: string,
            userRoles: string[]
        } = clientPrincipal;
        return typedPrincipal;
    }

    private msalConfig: Msal.Configuration = {
        auth: {
            clientId: ``, // Client Id of the registered application
            authority: ``,
        },
        //  cache: {
        //     cacheLocation: "sessionStorage",
        //     storeAuthStateInCookie: true
        // },
        // system: {

        //     logger: {
        //         localCallback: (level: Msal.LogLevel, message: string, containsPii: boolean): void => {
        //             if (containsPii) {
        //                 return;
        //             }
        //             switch (level) {
        //                 case Msal.LogLevel.Error:
        //                     console.error(message);
        //                     return;
        //                 case Msal.LogLevel.Info:
        //                     console.info(message);
        //                     return;
        //                 case Msal.LogLevel.Verbose:
        //                     console.debug(message);
        //                     return;
        //                 case Msal.LogLevel.Warning:
        //                     console.warn(message);
        //                     return;
        //             }
        //         },

        //         piiLoggingEnabled: false
        //     },
        //     windowHashTimeout: 60000,
        //     iframeHashTimeout: 6000,
        //     loadFrameTimeout: 0
        // }

    };

    private graphScopes = ["user.read", "user.readwrite"]; // An array of graph scopes

    private msalApplication: Msal.UserAgentApplication;
    private options = new MSALAuthenticationProviderOptions(this.graphScopes);
    // private authProvider = new ImplicitMSALAuthenticationProvider(this.msalApplication, this.options);
    // private clientOptions: MicrosoftGraph.ClientOptions;
    private tokenResponse: any = {};
    private noAuthError: boolean = false;
    private accessTokenResponse: any = {};
    // private client: MicrosoftGraph.Client ;
    // private client: any = {};
    private static graphConfig = {
        graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/",
        graphMeExtensionsEndpoint: "https://graph.microsoft.com/v1.0/me/extensions/"
    };


    static Instance: Office365API;
    public constructor(clientId: string, tenantId: string) {
        this.msalConfig.auth.clientId = `${clientId}`;
        this.msalConfig.auth.authority = `https://login.microsoftonline.com/${tenantId}`;
        this.msalApplication = new Msal.UserAgentApplication(this.msalConfig);
        this.msalApplication.handleRedirectCallback((error, response) => {
            console.log(this.msalConfig.auth);
            this.tokenResponse = response;
            console.log(this.tokenResponse);
            if (error) {
                this.noAuthError = true;
            }
        });
        Office365API.Instance = this;

    }
    private getTokenRedirect(request: any, user: string) {
        /**
         * See here for more info on account retrieval: 
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
        request.loginHint = user;

        if (user === "") {
            request.account = this.msalApplication.getAccount();

            if (!request.account && !this.noAuthError) {
                this.login();
            } else if (this.noAuthError) {
                /* eslint-disable */
                throw { code: 'InteractionRequiredAuthError' };
                /* eslint-enable */
            }
        }
        request.clientId = AppLoader.instance.state.settings.clientId;
        console.log(request);
        return this.msalApplication.acquireTokenSilent(request)
            .catch(error => {
                // console.warn("silent token acquisition fails. acquiring token using redirect");
                if (error instanceof Msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    // console.log('redirect needed');
                    return this.msalApplication.acquireTokenRedirect(request);

                } else {

                    // return this.msalApplication.acquireTokenRedirect(request);
                    // console.warn(error);
                }
            });
    }
    private async doRequest(endpoint: RequestInfo, method: "GET" | "POST" | "UPDATE" | "DELETE" | "PATCH" | "PUT", body?: any, addHeaders?: Headers) {
        if (!this.accessTokenResponse?.accessToken) {
            // console.log("aquirring token");
            let userPrincipal = await this.getUserInfo();
            let result = await this.getTokenRedirect({ scopes: this.graphScopes }, (userPrincipal)?userPrincipal.userDetails:'');
            // console.log("token:" + JSON.stringify(result));

            this.accessTokenResponse = result;
        }

        if (!this.accessTokenResponse?.accessToken) {
            // throw new GraphError(401, `No Token!${JSON.stringify(this.accessTokenResponse)}`);
        }
        const headers = new Headers();
        const bearer = `Bearer ${this.accessTokenResponse.accessToken}`;



        headers.append("Authorization", bearer);
        if (addHeaders) {
            addHeaders.forEach((value, key) => {
                headers.append(key, value);
            });
        }

        const options = {
            method: method,
            headers: headers,
            body: body !== '' ? body : null
        };

        return fetch(endpoint, options);

    }
    private async doJSONRequest(endpoint: RequestInfo, method: "GET" | "POST" | "UPDATE" | "DELETE" | "PATCH" | "PUT", body?: any, addHeaders?: Headers) {
        let response = await this.doRequest(endpoint, method, JSON.stringify(body), addHeaders);
        if (response.status !== 204)
            return response.json();
    }
    private async doBlobRequest(endpoint: RequestInfo, method: "GET" | "POST" | "UPDATE" | "DELETE" | "PATCH" | "PUT", body?: any, addHeaders?: Headers) {
        let response = await this.doRequest(endpoint, method, body, addHeaders)
        return response.blob();
    }
    public login() {
        if (!this.accessTokenResponse?.accessToken) {
            console.log(this.accessTokenResponse);
            this.msalApplication.loginRedirect();
        }
    }
    public async getMe() {

        // let userDetails = await this.client.api("/me").get();
        let userDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeEndpoint, "GET");

        return userDetails;
    }
    public async getMyExtensions() {
        // let extensionDetails = await this.client.api("/me/extensions").get();
        let extensionDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeExtensionsEndpoint, "GET");
        return extensionDetails;
    }
    public async getMyExtension(name: string) {
        // let extensionDetails = await this.client.api("/me/extensions/" + name).get();
        let extensionDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeExtensionsEndpoint + name, "GET");
        return extensionDetails;
    }
    public async updateMe(content: any) {
        // let userDetails = await this.client.api("/me").update(content);
        let userDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeEndpoint, "UPDATE", content);
        return userDetails;
    }
    public async deleteMyExtension(name: string) {
        // let extensionDetails = await this.client.api("/me/extensions/" + name).delete();
        let extensionDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeExtensionsEndpoint + name, "DELETE");
        return extensionDetails;
    }
    public async updateMyExtension(extensionID: string, contentToSet: any) {
        let content = {
            "@odata.type": "microsoft.graph.openTypeExtension",
            "id": extensionID,
            "extensionName": extensionID
        }
        // Object.assign(content, ...contentToSet);
        content = { ...content, ...contentToSet };
        let userDetails: any;
        let addHeaders = new Headers();
        addHeaders.append('Content-Type', 'application/json');

        // console.log(`PolicyDetails: ${JSON.stringify(App.instance.state.policyDetails)}`);
        if (App.instance.state.policyDetails.acceptanceDetails) {
            // userDetails = await this.client.api("/me/extensions/" + extensionID).patch(content);
            userDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeExtensionsEndpoint + extensionID, "PATCH", content, addHeaders);
        } else {
            // userDetails = await this.client.api("/me/extensions/").post(content);
            userDetails = await this.doJSONRequest(Office365API.graphConfig.graphMeExtensionsEndpoint, "POST", content, addHeaders);

        }
        return userDetails;
    }



    public async getMyPhotoUrl() {
        // let result = await this.client.api("/me/photo/$value").header('Cache-Control', 'no-cache')
        //     .responseType(MicrosoftGraph.ResponseType.BLOB).get();
        let addHeaders = new Headers();
        addHeaders.append('Cache-Control', 'no-cache');
        let result = await this.doBlobRequest(Office365API.graphConfig.graphMeEndpoint + 'photo/$value', "GET", '', addHeaders);


        let photoDetails = window.URL.createObjectURL(result);
        return photoDetails;
    }

    private async setProfileImage(file: File) {
        let addHeaders = new Headers();
        addHeaders.append('Content-Type', 'image/jpeg');
        let result = await this.doRequest(Office365API.graphConfig.graphMeEndpoint + 'photo/$value', "PUT", file, addHeaders);
        return result;
    }
    public async uploadProfileImage(file: File) {

        // let result = await this.client.api(`/me/photo/$value`)
        //     .put(file)
        //     ;
        let result = await this.setProfileImage(file);

        await this.updateMyExtension("vwg.imagePolicy", {
            "acceptanceDetails": {
                "acceptedVersion": App.instance.state.policyConfig.default["activeVersion"],
                "timestamp": Date.now()
            }
        });
        return result;
    }
    public async deleteProfileImage(file: File) {

        let result = await this.setProfileImage(file);

        await this.updateMyExtension("vwg.imagePolicy", {
            "acceptanceDetails": {
                "acceptedVersion": '-',
                "timestamp": Date.now()
            }
        });
        return result;
    }



}





