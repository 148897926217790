import { DefaultButton, IconButton, Image, Label, Modal, PrimaryButton, Separator, Stack, Toggle } from '@fluentui/react';
import { Depths } from '@uifabric/fluent-theme';
import React, { ChangeEvent } from 'react';
import { App } from '../../App';
import { I18n, I18nField } from '../../services/i18n';
import { Office365API } from '../../services/o365';
import { PolicyLoader } from '../../services/policyLoader';
import { ImageFineTune } from './ImageFineTune';
export class UploadPopUp extends React.Component<{
    isOpen: boolean,
    onDismiss?: ((ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => any) | undefined,
    contentStyles?: { container: string | undefined, header: string | undefined, body: string | undefined },
    onCancelImageUpload?: ((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => void) | undefined
}> {
    render() {
        return <Modal isOpen={this.props.isOpen} onDismiss={this.props.onDismiss} titleAriaId='UploadTitleArea' containerClassName={this.props.contentStyles?.container}  >

            <div className={this.props.contentStyles?.header}>
                <span id='UploadTitleArea'>
                    <I18nField textID='titleNewImage' />
                </span>

                <IconButton
                    styles={{
                        root: {
                            marginLeft: 'auto',
                            marginTop: '4px',
                            marginRight: '2px'
                        }
                    }}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={this.props.onCancelImageUpload}
                />
            </div>
            <div className={this.props.contentStyles?.body}>
                <Label htmlFor="policy">
                    <I18nField textID='policy_version' variables={[`${PolicyLoader.policyLoaderRef.current?.getActivePolicy()}`]} /></Label>
                <div id="policy" className={this.props.contentStyles?.body} dangerouslySetInnerHTML={{
                    __html: `${PolicyLoader.policyLoaderRef.current?.getPolicyConcatenated(`${PolicyLoader.policyLoaderRef.current?.getActivePolicy()}`)}`
                }} />

                <Toggle onText={I18n.i18nRef.current?.getString('accepted')} offText={I18n.i18nRef.current?.getString('declined')} styles={{ root: { marginTop: 20 } }} checked={App.instance.state.policyAccepted} onChange={() => { App.instance.setState({ policyAccepted: !App.instance.state.policyAccepted }) }} />
                <Separator />
                <div>
                    <input type='file' onChange={this.fileSelected.bind(this)} />
                </div>
                <Stack horizontal styles={{ root: { marginTop: 20 } }} tokens={{ childrenGap: 20 }}>

                    {/* <Pivot>
                        <PivotItem headerText={I18n.i18nRef.current?.getString('labelSelectedImage')}> */}
                    <Label htmlFor="previewImage" style={{ display: (App.instance.state.fileToUpload.size === undefined) ? "none" : "block" }}>
                        <I18nField textID="labelSelectedImage" /></Label>
                    {/* <Image id="previewImage"  height={648} width={648} src={App.instance.state.imageSelectionPreview} styles={{root:{display:'block',boxShadow:Depths.depth64},image:{opacity:1}}} /> */}
                    <div><canvas id="previewImage" style={{ boxShadow: Depths.depth64, display: (App.instance.state.fileToUpload.size === undefined) ? "none" : "block" }} width={648} height={648}></canvas></div>

                    {/* </PivotItem>
                    <PivotItem headerText={I18n.i18nRef.current?.getString('labelSelectImage')}> */}
                    <Image id="previewImageHidden" src={App.instance.state.imagePreview} styles={{ root: { display: 'none' } }} />
                    <Label htmlFor="previewImage" style={{ display: (App.instance.state.fileToUpload.size === undefined) ? "none" : "block" }}>
                        <I18nField textID="labelSelectImage" /></Label>
                    <div><ImageFineTune /></div>
                    {/* </PivotItem>
                    </Pivot> */}
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 20 } }} tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={this.uploadImage.bind(this)}
                        text={`${!App.instance.state.policyAccepted ? I18n.i18nRef.current?.getString('missingPolicyAcceptance') : ""}${(App.instance.state.fileToUpload.size === undefined) ? I18n.i18nRef.current?.getString('noImageUploaded') : ""}${(App.instance.state.policyAccepted && !(App.instance.state.fileToUpload.size === undefined)) ? I18n.i18nRef.current?.getString('save') : ""}`} disabled={!App.instance.state.policyAccepted || (App.instance.state.fileToUpload.size === undefined)} />
                    <DefaultButton onClick={this.props.onCancelImageUpload} text={I18n.i18nRef.current?.getString('cancel')} />
                </Stack>
            </div>
        </Modal>;
    }

    fileSelected(event: ChangeEvent<HTMLInputElement>) {
        if (event.nativeEvent && event.nativeEvent.target) {
            let test = (event.nativeEvent.target as any);
            App.instance.setState({ fileToUpload: test.files[0] });
            //PreviewImage
            var reader = new FileReader();
            reader.onload = function (e) {
                if (e.target)
                    App.instance.setState({ imagePreview: e.target.result });
            }
            reader.readAsDataURL(test.files[0]);
        } else {
            App.instance.setState({ fileToUpload: event.nativeEvent });
        }

    }

    uploadImage() {
        App.instance.setState({ spinner: true });

        var canvas = document.getElementById('previewImage') as HTMLCanvasElement;
        canvas.toBlob((theBlob) => {
            console.log(theBlob);
            var file = new File([theBlob as Blob], "yourImage.jpeg");
            Office365API.Instance.uploadProfileImage(file).then(() => {
                App.instance.resetDeleteState();
                App.instance.reloadState();
            });
        }, 'image/jpeg', 1);

        // Office365API.Instance.uploadProfileImage(App.instance.state.fileToUpload).then(() => {
        //     App.instance.resetUploadState();
        //     App.instance.reloadState();
        // });
    }
}