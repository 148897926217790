import { CommandBar, getTheme, ICommandBarItemProps, ICommandBarStyleProps, ICommandBarStyles, IComponentAs, Image, IStyleFunctionOrObject, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { NavigationSideBar } from './NavigationSideBar';

export class SidePanel extends React.Component<ISidePanelProperties> {
    private theme = getTheme();
    state = {
        isOpen: false
    };
    static defaultProps = {
        companyLogo: ""
    };
    private setIsOpen(openstate: boolean) {
        this.setState({ isOpen: openstate });
    };

    public openPanel() { this.setIsOpen(true) }
    public dismissPanel() {

        this.setIsOpen(false);
    }

    render() {
        return <Panel
            isLightDismiss={true}
            isOpen={this.state.isOpen}
            onDismiss={this.dismissPanel.bind(this)}
            isBlocking={true}
            isHiddenOnDismiss={true}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            onRenderNavigation={this._onRenderNavigationContent.bind(this)}
            type={PanelType.smallFixedNear}
            >
            <NavigationSideBar />
        </Panel>
    }

    private _onRenderNavigationContent() {


        let _logoConfig: IComponentAs<ICommandBarItemProps> = (commandProps) => {
            return <Image src={this.props.companyLogo} style={commandProps.style} styles={commandProps.buttonStyles} />;
        };
        let _items: ICommandBarItemProps[] = [
            {
                key: 'AppStarterToClose',
                text: 'schließen',
                iconProps: { iconName: 'WaffleOffice365' },
                iconOnly: true,
                buttonStyles: {
                    icon: {
                        color: this.theme.palette.themeDarker,
                        fontSize: this.theme.fonts.large.fontSize
                    },
                    iconHovered: { color: this.theme.palette.themeDarker }
                },
                onClick: this.dismissPanel.bind(this),
                style: {
                    width: '48px',
                    height: '48px'
                }
            },
            {
                key: 'AppLogo',
                text: 'App-Logo',
                commandBarButtonAs: _logoConfig,
                style: { paddingLeft: '24px', paddingTop: '8px', height: '32px' },
                buttonStyles: { root: { height: '48px' } }
            }
        ];
        let _style:
            IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> = {
            root: {
                paddingLeft: '0px',
                height: '48px'
            }
        };
        return <>
            <CommandBar styles={_style} items={_items} theme={this.theme}></CommandBar>
        </>
    }
}

export interface ISidePanelProperties extends React.Props<ISidePanelProperties> {
    companyLogo: string;
    ref: any;
}