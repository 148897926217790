import React from 'react';

export class ImageFineTune extends React.Component {
    state = {
        imgWidth: 648,
        imgHeight: 648,
        resizeFactor: 1

    }
    private isMouseDown: boolean = false;
    private isResizing: boolean = false;
    private resizeStyle: 'lt' | 'lb' | 'rt' | 'rb' | '' = '';
    private canvas!: HTMLCanvasElement;
    private previewImageHidden!: CanvasImageSource;
    private previewImage!: HTMLCanvasElement;
    private selector = { x: 0, y: 0, size: 648 };
    private resizer = { lt: { x: 0, y: 0 }, rt: { x: 0, y: 0 }, lb: { x: 0, y: 0 }, rb: { x: 0, y: 0 }, resizeSize: 20 };
    createImage() {
        try {
            this.previewImageHidden = document.getElementById('previewImageHidden') as CanvasImageSource;
            this.previewImage = document.getElementById('previewImage') as HTMLCanvasElement;
            //Call the getContext('2d') method and assign it to context object
            if (this.canvas) {
                var context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
                if (context) {
                    if (this.previewImageHidden?.height > 648 || this.previewImageHidden?.width > 648) {
                        let newFactor = 648 / (this.previewImageHidden?.height > this.previewImageHidden?.width ? this.previewImageHidden?.height as number : this.previewImageHidden?.width as number);
                        this.setState({ resizeFactor: newFactor });
                    }
                    this.setState({ imgHeight: this.previewImageHidden?.height as number * this.state.resizeFactor, imgWidth: this.previewImageHidden?.width as number * this.state.resizeFactor });

                    if (this.previewImageHidden.height > 0 && (this.state.imgHeight < this.selector.size || this.state.imgWidth < this.selector.size)) {
                        this.selector.size = ((this.state.imgHeight < this.state.imgWidth) ? this.state.imgHeight : this.state.imgWidth);
                    }
                    // context.font = 'bold 35pt Papyrus,Arial';
                    // context.fillText('HTML5 Canvas', 100, 100);
                    // VW-Blau füllen
                    context.fillStyle = '#C6DFE7';
                    context.fillRect(0, 0, this.state.imgWidth, this.state.imgHeight);


                    context.drawImage(this.previewImageHidden, 0, 0,this.state.imgWidth,this.state.imgHeight);

                    // Draw Image-Selector
                    context.fillStyle = "rgba(0, 0, 0, 0.5)";
                    context.fillRect(this.selector.x, this.selector.y, this.selector.size, this.selector.size);
                    context.strokeStyle = '#FFFFFF';
                    context.setLineDash([6]);
                    context.strokeRect(this.selector.x, this.selector.y, this.selector.size, this.selector.size);
                    context.fillStyle = "rgba(255, 255, 255, 0.9)";
                    context.setLineDash([]);
                    context.strokeStyle = '#000';
                    this.resizer.lt.x = this.selector.x - this.resizer.resizeSize / 2;
                    this.resizer.lt.y = this.selector.y - this.resizer.resizeSize / 2;

                    this.resizer.lb.x = this.selector.x - this.resizer.resizeSize / 2;
                    this.resizer.lb.y = this.selector.y + this.selector.size - this.resizer.resizeSize / 2;

                    this.resizer.rt.x = this.selector.x + this.selector.size - this.resizer.resizeSize / 2;
                    this.resizer.rt.y = this.selector.y - this.resizer.resizeSize / 2;

                    this.resizer.rb.x = this.selector.x + this.selector.size - this.resizer.resizeSize / 2;
                    this.resizer.rb.y = this.selector.y + this.selector.size - this.resizer.resizeSize / 2;


                    this.drawResizerRect(context, this.resizer.lt.x, this.resizer.lt.y, this.resizer.resizeSize);
                    this.drawResizerRect(context, this.resizer.lb.x, this.resizer.lb.y, this.resizer.resizeSize);

                    this.drawResizerRect(context, this.resizer.rt.x, this.resizer.rt.y, this.resizer.resizeSize);
                    this.drawResizerRect(context, this.resizer.rb.x, this.resizer.rb.y, this.resizer.resizeSize);

                    this.setPreviewImage();

                }
            }
        } catch (er) {

        }
    }
    setPreviewImage() {
        if (this.previewImage) {
            var context = this.previewImage.getContext('2d') as CanvasRenderingContext2D;
            context.drawImage(this.previewImageHidden, this.selector.x/this.state.resizeFactor, this.selector.y/this.state.resizeFactor, this.selector.size/this.state.resizeFactor, this.selector.size/this.state.resizeFactor, 0, 0, 648, 648);
        }
    }

    getPresentCursor(ev: MouseEvent) {
        if (this.isOnAnyResizer(ev)) {
            if (this.isOnResizer(this.resizer.lt, ev)) {
                return 'nw-resize';
            }
            if (this.isOnResizer(this.resizer.lb, ev)) {
                return 'sw-resize';
            }
            if (this.isOnResizer(this.resizer.rt, ev)) {
                return 'ne-resize';
            }
            if (this.isOnResizer(this.resizer.rb, ev)) {
                return 'se-resize';
            }
            return 'default';
        } else if (this.isOnSpace(this.selector, ev)) {
            return 'move';
        } else {

            return 'default';
        }
    }
    isOnResizer(resizer: { x: number, y: number }, ev: MouseEvent) {
        let proofX = resizer.x;
        let proofY = resizer.y;
        let proofSize = this.resizer.resizeSize;
        return this.isOnSpace({ x: proofX, y: proofY, size: proofSize }, ev);
    }
    isOnAnyResizer(ev: MouseEvent) {
        if (this.isOnResizer(this.resizer.lt, ev)) {
            return true;
        }
        if (this.isOnResizer(this.resizer.lb, ev)) {
            return true;
        }
        if (this.isOnResizer(this.resizer.rt, ev)) {
            return true;
        }
        if (this.isOnResizer(this.resizer.rb, ev)) {
            return true;
        }
        return false;
    }
    isOnSpace(layer: { x: number, y: number, size: number }, ev: MouseEvent) {
        if (ev.offsetX >= layer.x && ev.offsetX <= layer.x + layer.size
            && ev.offsetY >= layer.y && ev.offsetY <= layer.y + layer.size)
            return true;
        return false;
    }
    drawResizerRect(context: CanvasRenderingContext2D, x: number, y: number, size: number) {
        context.strokeRect(x, y, size, size);
        context.fillRect(x, y, size, size);

    }
    componentDidMount() {
        //Use "getElementById" method on document object to find the canvas element

        this.canvas = document.getElementById('ImageCreator') as HTMLCanvasElement;
        this.canvas.onmousedown = (ev) => {
            this.isMouseDown = true;
            if (this.isOnAnyResizer(ev)) {
                this.isResizing = true;
                if (this.isOnResizer(this.resizer.lt, ev)) {
                    this.resizeStyle = 'lt';
                }
                if (this.isOnResizer(this.resizer.lb, ev)) {
                    this.resizeStyle = 'lb';
                }
                if (this.isOnResizer(this.resizer.rt, ev)) {
                    this.resizeStyle = 'rt';
                }
                if (this.isOnResizer(this.resizer.rb, ev)) {
                    this.resizeStyle = 'rb';
                }
            }
        };
        this.canvas.onmousemove = (ev) => {


            if (this.isMouseDown && this.isResizing) {
                this.resizeSelection(ev);
            } else if (this.isMouseDown && this.isOnSpace(this.selector, ev)) {
                this.moveSelection(ev);
            }


            this.canvas.style.cursor = this.getPresentCursor(ev);

        };
        this.canvas.onmouseup = (ev) => { this.isMouseDown = false; this.isResizing = false; };
        this.createImage();
        setInterval(this.createImage.bind(this), 1);


    }
    resizeSelection(ev: MouseEvent) {
        switch (this.resizeStyle) {
            case 'rb':
                this.selector.size += ev.movementX;
                break;
            case 'rt':
                this.selector.y += ev.movementY;
                this.selector.size += ev.movementX;
                break;
            case 'lb':
                this.selector.x += ev.movementX;
                this.selector.size += ev.movementY;
                break;
            case 'lt':
                this.selector.y += ev.movementY;
                this.selector.x += ev.movementX;
                this.selector.size -= ev.movementX;
                break;

            default:
                break;
        }
    }
    moveSelection(ev: MouseEvent) {

        this.selector.x += ev.movementX;
        this.selector.y += ev.movementY;
        if (this.selector.x < 0) this.selector.x = 0;
        if (this.selector.y < 0) this.selector.y = 0;
        if (this.selector.x + this.selector.size > this.state.imgWidth) { this.selector.x = this.state.imgWidth - this.selector.size; }
        if (this.selector.y + this.selector.size > this.state.imgHeight) { this.selector.y = this.state.imgHeight - this.selector.size; }
    }
    render() {

        return <><canvas id="ImageCreator" style={{ border: 'solid 1px #000' }} width={`${this.state.imgWidth}px`} height={`${this.state.imgHeight}px`}></canvas></>;
    }
}