import { Spinner } from '@fluentui/react';
import React from 'react';
import { App } from '../App';
import { Office365API } from '../services/o365';

export class AppLoader extends React.Component {
    state = {
        AppLoaded: false,
        settings: {} as any
        
    }
    public static instance: AppLoader;
    componentDidMount() {

        this.loadSettings();
    }
    render() {
        AppLoader.instance = this;
        return this.state.AppLoaded ? <App /> : <><Spinner>App loading</Spinner></>;
    }

    loadSettings() {
        let settingURL = 'api/getSettings';
        if (window.location.hostname === 'localhost') {
            settingURL = 'sampleData/getSettings/localhost.dat';
        }
        fetch(settingURL).then((response) => {
            response.json().then((jsonData) => {

                this.setState({ settings: jsonData });
                new Office365API(this.state.settings.clientId,this.state.settings.tenantId);
                this.setLoadState();
            });
        });
    }
    setLoadState() {
        if (this.state.settings !== undefined) {
            this.setState({ AppLoaded: true });
        }
    }
}