import { FluentCustomizations } from '@uifabric/fluent-theme';
import { Customizer, mergeStyles } from 'office-ui-fabric-react';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppLoader } from './ui/loader';

// Providers.globalProvider = new MsalProvider({
//   clientId: '2e99e35e-4410-4968-9c01-e76f2bc485f3',
//   authority: "https://login.microsoftonline.com/36bf0149-5b2b-4158-896d-e409b491f89b",
//   scopes: ["user.read", "user.readwrite"],
//   loginType: LoginType.Popup
// });

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

ReactDOM.render(
  <Customizer {...FluentCustomizations}>

    <AppLoader />
    {/* {JSON.stringify(process.env)}<hr />
    {JSON.stringify(App.instance?.state.settings)} */}
  </Customizer>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
