import { CommandBar, ICommandBarItemProps, Image, Label, PrimaryButton, Separator, Stack, Text } from '@fluentui/react';
import { Depths } from '@uifabric/fluent-theme';
import React from 'react';
import { App } from '../../App';
import { I18n, I18nField } from '../../services/i18n';
import { Office365API } from '../../services/o365';
import { ContentPane } from '../components/ContentPane';
import { PolicyPage } from './policies';





export class EditorPage extends React.Component {

  state = {
    debuging: false,
    policyAllDetails: ({} as any)
  };
  componentDidMount() {

    Office365API.Instance.getMyPhotoUrl().then((photoDetails) => {
      App.instance.setState({ photoDetails: photoDetails });
    });
    Office365API.Instance.getMyExtension("vwg.imagePolicy").then((policyDetails) => {
      App.instance.setState({ policyDetails: policyDetails });
    });

    Office365API.Instance.getMyExtensions().then((policyDetails) => {
      this.setState({ policyAllDetails: policyDetails });
    });

  }
  render() {
    let imageComponent = <Stack>
      <Text><I18nField textID='waitingOnImage' /></Text>
    </Stack>;
    if (typeof (App.instance.state.photoDetails) === 'string') {

      imageComponent = <><Label htmlFor="activeImage"><I18nField textID='labelPresentImage' /></Label>
        <Image id="activeImage" src={App.instance.state.photoDetails} width={250} styles={{ root: { boxShadow: Depths.depth8 } }} />
      </>;

    }
    let debugInfoProfileImage = <></>;
    let debugInfoProfile = <></>;
    if (this.state.debuging) {
      debugInfoProfileImage = <><Separator /><Label htmlFor='extensionPlain'>JSON</Label>
        <Text id='extensionPlain'>{JSON.stringify(App.instance.state.policyDetails)}</Text>
        <Label htmlFor='extensionAllPlain'>JSON(All)</Label>
        <Text id='extensionAllPlain'>{JSON.stringify(this.state.policyAllDetails)}</Text>
        <Separator />
        <PrimaryButton onClick={() => { Office365API.Instance.deleteMyExtension("vwg.imagePolicy") }}><I18nField textID='repair' /></PrimaryButton>
      </>;
      debugInfoProfile = <><Separator /><Label htmlFor='profilePlain' >JSON</Label>
        <Text id='profilePlain'>{JSON.stringify(App.instance.state.o365User)}</Text>
      </>;
    }
    // Warnung generieren
    let oldPolicy: JSX.Element = <></>;
    let hasNoPolicy = false;
    if (App.instance.state.policyDetails.acceptanceDetails) {
      if (App.instance.state.policyDetails.acceptanceDetails.acceptedVersion !== App.instance.state.policyConfig["default"]["activeVersion"]) {
        oldPolicy = <>
          <Label htmlFor="activatePolicy" styles={{ root: { color: "red" } }}><I18nField textID='labelPolicyWarning' /></Label>
          <PrimaryButton id="activatePolicy" onClick={this.acceptNewPolicy.bind(this)} >
            <I18nField textID='newPolicy_version' variables={[App.instance.state.policyConfig["default"]["activeVersion"]]} />
          </PrimaryButton>
        </>;
      }
      if (App.instance.state.policyDetails.acceptanceDetails.acceptedVersion === '-' || App.instance.state.policyDetails.acceptanceDetails.acceptedVersion === undefined || App.instance.state.policyDetails.acceptanceDetails.acceptedVersion === '') {
        hasNoPolicy = true;
      }
    } else {
      hasNoPolicy = true;
    }
    let policyElements: JSX.Element = <></>;
    if (!hasNoPolicy) {
      policyElements = <><Label htmlFor='acceptedPolicy'><I18nField textID='labelConsent' /></Label><Text id='acceptedPolicy'><I18nField textID='version' variables={[App.instance.state.policyDetails.acceptanceDetails ? App.instance.state.policyDetails.acceptanceDetails.acceptedVersion : ""]} /></Text>
        <Text id='acceptedPolicyDate'><I18nField textID='acctepted_on' variables={[App.instance.state.policyDetails.acceptanceDetails ? new Date(App.instance.state.policyDetails.acceptanceDetails.timestamp).toLocaleString() : ""]} /></Text>
        {oldPolicy}
      </>;
    } else {
      // policyElements = <><Text id='acceptedPolicy'><I18nField textID='policyNotAccepted' /></Text>
      // </>;
    }
    let _cmdItems: ICommandBarItemProps[] = [
      {
        key: 'newImage',
        iconProps: { iconName: 'Photo2Add' },
        text: I18n.i18nRef.current?.getString('titleNewImage'),
        onClick: this.startImageUpload.bind(this)
      },
      {
        key: 'deleteImage',
        iconProps: { iconName: 'Delete' },
        text: I18n.i18nRef.current?.getString('delete_image'),
        onClick: this.startImageDelete.bind(this)
      }];
    return <Stack styles={{ root: { paddingTop: 10, paddingLeft: 10, paddingRight: 10, height: '100%' }, inner: { alignItems: "center", verticalAlign: 'middle' } }
    } horizontal >
      {/* <Toggle label="Debugging" checked={this.state.debuging} onChange={() => { this.setState({ debuging: !this.state.debuging }) }} /> */}
      <ContentPane headerText={I18n.i18nRef.current?.getString('headerProfileinformation')}>

        <Label htmlFor='profileName'><I18nField textID='labelProfileName' /></Label>
        <Text id='profileName'>{App.instance.state.o365User['displayName']}</Text>
        <Label htmlFor='officeLocation'><I18nField textID='labelOfficeLocation' /></Label>
        <Text id='officeLocation'>{App.instance.state.o365User['officeLocation']}</Text>
        {debugInfoProfile}
      </ContentPane>
      <ContentPane headerText={I18n.i18nRef.current?.getString('headerYourImage')}>
        <CommandBar items={_cmdItems} style={{ width: 500 }} />
        
        {policyElements}
        {imageComponent}
        {debugInfoProfileImage}
      </ContentPane>
      <PolicyPage />
    </Stack >;
  }
  startImageUpload() {
    App.instance.setState({ uploadOpen: true });
  }
  startImageDelete() {
    App.instance.setState({ deleteImageOpen: true });
  }
  acceptNewPolicy() {
    Office365API.Instance.updateMyExtension("vwg.imagePolicy", {
      "acceptanceDetails": {
        "acceptedVersion": App.instance.state.policyConfig.default["activeVersion"],
        "timestamp": Date.now()
      }
    }).then(() => {
      App.instance.reloadState();
    });
  }
};

